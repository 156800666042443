import React from "react"
import Title from "../title"
import Icon from "../../assets/svg/factory.svg"

const Business = () => (
  <div className="container mx-auto pt-4 pb-12">

    <div className="w-full mb-4">
      <Title border="border-l-8" title="Business Services"/>
    </div>

    <div className="flex flex-wrap">
      <div className="w-5/6 sm:w-1/2 mx-auto py-6 text-center lg:text-left">
        <h3 className="text-1xl text-gray-800 font-black leading-none mb-3">
          Buying and selling of large or small quantities
        </h3>
        <h3 className="text-1xl text-gray-800 font-black leading-none mb-3">UK and international sales</h3>
        <h3 className="text-1xl text-gray-800 font-black leading-none mb-3">Hosting a buying group to help and benefit
          the business and other sellers</h3>
        <h3 className="text-1xl text-gray-800 font-black leading-none mb-3">Amazon Prime Partner, Royal Mail
          certified</h3>
        <h3 className="text-1xl text-gray-800 font-black leading-none mb-3">Prep and fulfilment centre – packaging,
          storage, labelling, shipping, returns</h3>
        <h3 className="text-1xl text-gray-800 font-black leading-none mb-3">Expert consultancy services</h3>
        <h3 className="text-1xl text-gray-800 font-black leading-none mb-3">VAT registered</h3>
      </div>
      <div className="w-full sm:w-1/2 py-6 hidden lg:block">
        <Icon className="w-full sm:h-64 mx-auto"/>
      </div>
    </div>

  </div>
)

export default Business