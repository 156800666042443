import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/sections/intro"
import Business from "../components/sections/business"
import BusinessToConsumer from "../components/sections/businessConsumer"
import Image from "../components/image"

const IndexPage = () => (
  <Layout>
    <SEO title="Home"/>
    <section className={`pt-20 max-w-4xl mx-auto`}>
      <Image/>
    </section>
    <section className="pb-10 border-b">
      <Intro/>
    </section>
    <section className="py-10 bg-gray-100 border-b">
      <BusinessToConsumer/>
    </section>
    <section className="py-10 border-b">
      <Business/>
    </section>
  </Layout>
)

export default IndexPage
