import React from "react"
import Title from "../title"
import Icon from "../../assets/svg/about.svg"

const Intro = () => (
    <div className="container w-full lg:w-3/4 bg-grey mx-auto flex flex-wrap flex-col md:flex-row items-center">
      <div className="flex flex-col w-full lg:w-1/2 justify-center items-start text-center lg:text-left">
        <Title title="About us" border="border-l-8"/>
        <p className="leading-thin text-lg mt-10"><strong>Monaghan Media Ltd</strong> is a specialist retail and wholesale company.
          Primarily we sell toys, computer games and general geek merchandise, both new and pre-owned stock.</p>
        <p className="leading-text text-lg mt-8">From humble beginnings in 2012, Monaghan Media has grown rapidly. The business is run from a warehouse in Bedfordshire, and have revenues exceeding £500,000 annually.</p>
      </div>
      <div className="w-full hidden lg:block lg:w-1/2 py-6 text-center">
        <div className="w-full max-w-lg mx-auto">
          <Icon className="max-w-lg"/>
        </div>
      </div>
    </div>
)

export default Intro