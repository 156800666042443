import React from "react"
import Title from "../title"
import Icon from "../../assets/svg/globe.svg"

const BusinessToConsumer = () => (
  <div className="container mx-auto pt-4 pb-12">
    <div className="flex flex-wrap flex-col-reverse sm:flex-row">
      <div className="w-full lg:w-1/2 p-6 mt-6 hidden lg:block">
        <Icon className="w-full sm:h-64"/>
      </div>
      <div className="w-full lg:w-1/2 p-6 mt-6 text-center lg:text-right">
        <Title border="border-r-8" align="lg:text-right" title="Business to consumer"/>
        <div className="mt-10">
          <h3 className="text-1xl text-gray-800 font-black leading-none mb-3">Online retail, Trading on Amazon, eBay,
            GAME Marketplace and Shopify</h3>
          <h3 className="text-1xl text-gray-800 font-black leading-none mb-3">Amazon Prime partner</h3>
          <h3 className="text-1xl text-gray-800 font-black leading-none mb-3">Over 10k positive feedback on eBay and
            over 1000 feedback on Amazon</h3>
        </div>
      </div>
    </div>
  </div>
)

export default BusinessToConsumer